<div class="contracts-list-container">
    @if (isLoading) {
    <div class="no-applicants">
        <staff-loader [customClass]="'splash-screen-loader'"></staff-loader>
    </div>
    } @else {
    <div class="applicants-list">
        @if(contractsList.length > 0) {
        <app-date-information-alert [contracts]="contractsList"> </app-date-information-alert>
        <table>
            <caption class="hidden-caption">
                Contracts list Job Openings
            </caption>
            <thead>
                <tr>
                    @for (col of colNames; track col) { @if(isCenterColumn(col)) {
                    <th class="align-column-text">
                        <span>{{ col | translate }}</span>
                    </th>
                    }@else {
                    <th>
                        <span class="col-name">{{ col | translate }}</span>
                    </th>
                    } }
                </tr>
            </thead>
            <tbody>
                @for (contract of contractsList; track contract.id) {
                <tr
                    [ngClass]="{
                        expired: contract.isExpired,
                        finished: contract.isFinished
                    }"
                    class="single-contract-row"
                >
                    <td>
                        <div class="technician-info">
                            @if(contract?._links?.profilePicture){
                            <div
                                [ngStyle]="{
                                    'background-image': 'url(\'' + contract?._links?.profilePicture.href + '\')'
                                }"
                                class="profile-pic"
                            ></div>
                            } @else {
                            <div class="profile-pic">
                                <i class="material-icons"> person </i>
                            </div>
                            }
                            <span class="h6">
                                {{ contract?._embedded?.profile?.firstName }}
                                {{ contract?._embedded?.profile?.lastName }}
                            </span>
                        </div>
                    </td>
                    <td class="offer-date">
                        <app-offer-start-date
                            [contract]="contract"
                            [offer]="getJobOfferAt(expandedRowIndex)"
                        ></app-offer-start-date>
                    </td>
                    <td class="offer-date">
                        <app-offer-end-date
                            [contract]="contract"
                            [offerStartDate]="getStartDateOffer(expandedRowIndex)"
                        >
                        </app-offer-end-date>
                    </td>
                    <td class="contract-status">
                        <contract-status
                            (openPublicProfile)="onOpenPublicProfile($event, contract)"
                            [contract]="contract"
                        >
                        </contract-status>
                    </td>
                    <td>
                        <div class="rejected-or-pending-or-acceptance-reasons">
                            @if (getAcceptanceNotes(contract)) {
                            <span>
                                {{ getAcceptanceNotes(contract) }}
                            </span>
                            } @if(getPendingReason(contract)){
                            <staffnow-reasons-list-item
                                [reason]="getPendingReason(contract)"
                            ></staffnow-reasons-list-item>
                            } @for(reason of contract.rejectedReasons; track reason.id) {
                            <staffnow-reasons-list-item [reason]="reason"></staffnow-reasons-list-item>
                            }
                        </div>
                    </td>
                    <td>
                        <app-contract-action-table
                            [offerStartDate]="getStartDateOffer(expandedRowIndex)"
                            [offerEndDate]="getEndDateOffer(expandedRowIndex)"
                            [contract]="contract"
                            [reasonsDeclineOffer]="getReasonsDeclineOffer(expandedRowIndex)"
                        >
                        </app-contract-action-table>
                    </td>
                </tr>
                }
            </tbody>
        </table>
        } @else {
        <h1 class="h4">
            {{ 'AGENCY.DETAILS.NO_APPLICANTS' | translate }}
        </h1>
        }
    </div>
    }
</div>
