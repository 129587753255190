import { Component, Input } from '@angular/core';
import { addUtils, Contract, ContractWithUtils } from '@libs/shared/models/contract.model';
import { OfferOutDto } from '@libs/shared/models/offer.model';
import { GenericOfferType } from '@libs/shared/models/genericOffer.type';
import { AbstractExpandComponent } from '../../../../../../../back-office/src/app/modules/admin-request-overview/components/abstract-expand.component';
import { ExtendedPendingReason } from '@libs/shared/models/reasons.model';
import { EntityTypes } from '@libs/shared/models/entity-types.enum';
import { LoadPublicProfile } from '@libs/request-overview-common/state/requests-overview.actions';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-contracts-list-jo-expand',
  templateUrl: './contracts-list-jo-expand.component.html',
  styleUrls: ['./contracts-list-jo-expand.component.scss']
})
export class ContractsListJoExpandComponent extends AbstractExpandComponent {
  @Input() isLoading: boolean;
  @Input() contractsList: Array<Contract> = [];
  @Input() offerList: Array<GenericOfferType> = [];
  @Input() expandedRowIndex: number = null;
  colNames: string[] = [];
  nameCenteredColumns: string[] = [];

  constructor(public store: Store) {
    super();
    this.initializeColNames();
    this.initializeNameCenteredColumns();
  }

  initializeColNames(): void {
    this.colNames = [
      'TABLES.LABEL.GENERAL_NAME',
      'TABLES.LABEL.GENERAL_START_DATE',
      'TABLES.LABEL.GENERAL_END_DATE',
      'TABLES.LABEL.GENERAL_CONTRACT_STATUS',
      'TABLES.LABEL.GENERAL_NOTES',
      'TABLES.LABEL.GENERAL_ACTIONS'
    ];
  }

  initializeNameCenteredColumns(): void {
    this.nameCenteredColumns = [
      'TABLES.LABEL.GENERAL_CONTRACT_STATUS',
      'TABLES.LABEL.GENERAL_START_DATE',
      'TABLES.LABEL.GENERAL_END_DATE',
      'TABLES.LABEL.GENERAL_NOTES',
      'TABLES.LABEL.GENERAL_ACTIONS'
    ];
  }

  isCenterColumn(colName: string): boolean {
    return this.nameCenteredColumns.includes(colName);
  }

  onOpenPublicProfile(entityType: EntityTypes, contract: Contract): void {
    this.store.dispatch(LoadPublicProfile({ contract: contract, entityType }));
  }

  getJobOfferAt(index: number): OfferOutDto {
    return this.offerList[index] as OfferOutDto;
  }

  getStartDateOffer(index: number): string {
    const offer: OfferOutDto = this.getJobOfferAt(index);
    return offer.periodFrom ? offer.periodFrom : '';
  }

  getEndDateOffer(index: number): string {
    const offer: OfferOutDto = this.getJobOfferAt(index);
    return offer.periodTo ? offer.periodTo : '';
  }

  getAcceptanceNotes(contract: Contract): string {
    const contractWithUtils: ContractWithUtils = addUtils(contract);
    return contractWithUtils.hasAcceptanceNotes() ? contractWithUtils.getAcceptanceNotes() : null;
  }

  getPendingReason(contract: Contract): ExtendedPendingReason {
    const contractWithUtils: ContractWithUtils = addUtils(contract);
    return contractWithUtils.hasPendingReasons()
      ? {
          ...contractWithUtils.getPendingReason(),
          name: 'More Documents Needed'
        }
      : null;
  }

  getReasonsDeclineOffer(index: number): any[] {
    return (this.getJobOfferAt(index) as any)._embedded?.reasons;
  }
}
