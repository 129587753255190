import { Component, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, debounceTime, Observable, OperatorFunction, Subscription, switchMap } from 'rxjs';
import {
  FailedToLoadList,
  ListLoaded,
  LoadList,
  LoadListBO,
  OfferLoaded,
  ResetRequestsOverviewState,
  SetActiveFilter,
  SetFacilityUuid,
  SetFilterPage,
  SetOfferType,
  SetRefNumber,
  SuccessfulDeletedAgencyOffer,
  SuccessfulDeletedOffer
} from '@libs/request-overview-common/state/requests-overview.actions';
import { cloneDeep, identity, keys, pickBy } from 'lodash-es';
import { Actions, ofType } from '@ngrx/effects';
import {
  selectPage,
  selectPageSize,
  selectResultList,
  selectSelectedEntityUuid,
  selectTotalElements
} from '@libs/request-overview-common/state/requests-overview.selectors';
import { role, RoleWrapper, UserRoles } from '@libs/shared/models/roles.enum';
import { CUSTOM_REFERENCE_NUMBER_MAX_LENGTH, OfferType } from '@libs/shared/models/offer.model';
import { getFilteredApiRoot, getLoggedInUserRole } from '@libs/shared/bms-common/api-root/api-root.selectors';
import { getEmbeddedResource, getUrl } from '@libs/shared/bms-common/rest/resource.utils';
import { ApiRootLinkRel } from '@libs/shared/linkrels/api-root.linkrel';
import { OfferSubmitSuccess } from '@libs/create-offer-page/create-offer.actions';
import { SimpleFacilityWithUuid } from '@libs/shared/models/facility.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ApiRootResource } from '@libs/shared/bms-common/api-root/api-root.model';
import { AgencyOfferSubmitSuccess } from '@libs/create-agency-offer-page/create-agency-offer.actions';
import { isPlatformWeb } from '@libs/shared/helpers/capacitor';
import { TranslateService } from '@ngx-translate/core';
import { MroFacilityLoaderService } from '../../../../../../../../libs/offer-management/shared/services/mro-facility-loader.service';
import { MroFacilitySimple } from '../../../../state/app-state.model';
import { map, tap, withLatestFrom } from 'rxjs/operators';
import { JobOpeningFiltersConstants } from '@platform/shared/constants/job-opening-filters.constants';
import { GenericOfferType } from '@libs/shared/models/genericOffer.type';

interface Filter {
  filter: string;
  label: string;
}

@UntilDestroy()
@Component({
  selector: 'staffnow-request-listing',
  templateUrl: './request-listing.component.html',
  styleUrls: ['./request-listing.component.scss']
})
export class RequestListingComponent implements OnInit, OnDestroy {
  readonly CUSTOM_REFERENCE_NUMBER_MAX_LENGTH = CUSTOM_REFERENCE_NUMBER_MAX_LENGTH;
  @Input() actingAs: UserRoles = null;
  @Input() isMyAgencyTab: boolean = false;

  selectedFilter: string = null;
  hideFilters = !isPlatformWeb();
  overflowHidden = !isPlatformWeb();
  offerList: Array<GenericOfferType> = [];
  effectiveRole: RoleWrapper = null;
  isLoading: boolean = false;
  page = 0;
  pageSize = 0;
  totalElements = 0;
  filters;
  refNumberSearch: string = null;
  userRole: RoleWrapper = null;
  selectedTechnicianUuid: string = '';
  component = null;
  offerType: OfferType = null;

  selectedFacilityUuid: string;
  private defaultFilter: string = JobOpeningFiltersConstants.quickFilter.active;
  private defaultApplicationsFilter: string = JobOpeningFiltersConstants.applicationFilter.allApplications;
  private defaultPage: number = 0;
  private subscription: Subscription = new Subscription();

  loggedInUserRole: RoleWrapper = null;
  private apiRoot: ApiRootResource = null;
  isLastPage = false;
  entitySearchObservable = new BehaviorSubject({
    term: '',
    pageSize: 20,
    page: 0,
    facilities: [] as MroFacilitySimple[],
    offerType: this.offerType
  });
  entityList$: Observable<MroFacilitySimple[]>;
  hasMros: boolean = false;
  loading: boolean = false;

  mobileBreakpoint: number = 768;
  isSmallScreen: boolean = window.innerWidth < this.mobileBreakpoint;

  currentApplicationsFilter: Filter = null;

  protected readonly isPlatformWeb: boolean = isPlatformWeb();

  constructor(
    public store: Store<any>,
    public actions: Actions,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private mroLoaderService: MroFacilityLoaderService,
    private translateService: TranslateService
  ) {
    this.store.pipe(getLoggedInUserRole, untilDestroyed(this)).subscribe(r => {
      this.loggedInUserRole = role(r);
    });
  }

  ngOnInit(): void {
    this.setupStoreSubscriptions();
    this.setupActionsSubscriptions();
    this.watchQueryParams();
    this.initializeMroFacilitiesObservable();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.store.dispatch(ResetRequestsOverviewState());
  }

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.isSmallScreen = window.innerWidth < this.mobileBreakpoint;
  }

  pageChanged($event): void {
    this.search(undefined, $event.page);
  }

  getEnabledFilterKey(): string[] {
    return keys(pickBy(this.filters, identity));
  }

  clearSearch(): void {
    this.refNumberSearch = null;
    this.onSearchByRefNumber();
  }

  pressedKeyValidation($event): void {
    this.refNumberSearch = this.refNumberSearch?.trim();
    const { keyCode, key } = $event;
    const pattern = new RegExp(/^[a-z0-9]+$/i);

    if (!key.match(pattern) || this.refNumberSearch?.length >= 8) {
      $event.preventDefault();
    }

    const ENTER_KEY = 13;
    if (keyCode === ENTER_KEY) {
      this.onSearchByRefNumber();
    }
  }

  onSearchByRefNumber(): void {
    this.store.dispatch(SetRefNumber({ refNumber: this.refNumberSearch }));
    this.search();
  }

  toggleFilters(selectedFilter: string): void {
    this.selectedFilter = selectedFilter;
    for (const filter of Object.keys(this.filters)) {
      if (this.filters[filter]) {
        const selectedFilterIsQuickFilter: boolean = this.isQuickFilter(selectedFilter);
        const currentFilterIsQuickFilter: boolean = this.isQuickFilter(filter);

        if ((selectedFilterIsQuickFilter && currentFilterIsQuickFilter) || !currentFilterIsQuickFilter) {
          this.filters[filter] = false;
          this.filters[selectedFilter] = true;
          break;
        }
      }
    }

    this.offerList = [];
    this.search(this.getEnabledFilterKey());
  }

  isQuickFilter(filter: string): boolean {
    return Object.values(JobOpeningFiltersConstants.quickFilter).includes(filter);
  }

  search(filter: string[] = this.getEnabledFilterKey(), page: number = 1): void {
    this.toggleHideFilters();
    this.router.navigate([], {
      queryParamsHandling: 'merge',
      queryParams: {
        filter: filter,
        refNumber: this.refNumberSearch,
        page: page,
        facilityUuid: this.selectedFacilityUuid
      }
    });
  }

  setEffectiveRole(userRoles: UserRoles | string): void {
    this.effectiveRole = role(userRoles);
  }

  navigateToCreateTemporaryJobOpeningForm(): void {
    this.router.navigate(['offer'], {
      state: {
        preselectedFilters: {},
        isPrivate: false,
        selectedTechnicians: [],
        isEdit: false,
        mroUuid: this.selectedFacilityUuid,
        breadcrumbs: [
          this.translateService.instant('AGENCY.DETAILS.TITLE_JO_APPLICATIONS'),
          this.translateService.instant('REQUEST_LISTING.CREATE_TEMPORARY_JOB_OPENING')
        ]
      }
    });
  }

  navigateToCreatePermanentJobOpeningForm(): void {
    this.router.navigate(['permanent-offer'], {
      state: {
        preselectedFilters: {},
        isPrivate: false,
        selectedTechnicians: [],
        isEdit: false,
        mroUuid: this.selectedFacilityUuid,
        breadcrumbs: [
          this.translateService.instant('AGENCY.DETAILS.TITLE_JO_APPLICATIONS'),
          this.translateService.instant('REQUEST_LISTING.CREATE_PERMANENT_JOB_OPENING')
        ]
      }
    });
  }

  navigateToCreatePackageOfferForm(): void {
    this.router.navigate(['package-offer'], {
      state: {
        isEdit: false,
        mroUuid: this.selectedFacilityUuid,
        breadcrumbs: [
          this.translateService.instant('AGENCY.DETAILS.TITLE_JO_APPLICATIONS'),
          this.translateService.instant('REQUEST_LISTING.CREATE_FIXED_PRICE_JOB_OPENING')
        ]
      }
    });
  }

  onSearch(term: string): void {
    this.entitySearchObservable.next({
      page: 0,
      term,
      pageSize: 20,
      facilities: [],
      offerType: this.offerType
    });
  }

  onClose(): void {
    this.onSearch('');
  }

  loadMore(facilities: MroFacilitySimple[]) {
    const currentPageableValues = this.entitySearchObservable.getValue();
    this.entitySearchObservable.next({
      ...currentPageableValues,
      facilities,
      page: currentPageableValues.page + 1
    });
  }

  onSelectedMroChange(selectedFacility: SimpleFacilityWithUuid): void {
    this.selectedFacilityUuid = selectedFacility?.uuid;
    this.search();
  }

  loadMoreOffers() {
    this.page = this.page + 1;
    this.store.dispatch(SetFilterPage({ page: this.page - 1 }));
    this.triggerListLoad();
  }

  toggleHideFilters(): void {
    this.hideFilters = !this.hideFilters;
    if (this.hideFilters) {
      this.toggleOverflow();
    } else {
      setTimeout(() => this.toggleOverflow(), 1000);
    }
  }

  toggleOverflow(): void {
    this.overflowHidden = !this.overflowHidden;
  }

  get shouldDisplayPending(): boolean {
    if (this.offerType == OfferType.FIXED_PRICE || this.offerType == OfferType.AGENCY) {
      return this.isAgency;
    }
    return this.isTechnician;
  }

  get shouldDisplayDocumentsNeeded(): boolean {
    if (this.offerType == OfferType.FIXED_PRICE) {
      return false;
    }
    return !this.isMro;
  }

  get shouldDisplayRejected(): boolean {
    if (this.isMro) {
      return false;
    } else if (this.offerType == OfferType.FIXED_PRICE) {
      return !this.isTechnician;
    }
    return true;
  }

  get isMro(): boolean {
    return this.userRole.isMro();
  }

  get isTechnician(): boolean {
    return this.userRole.isTechnician();
  }

  get isAgency(): boolean {
    return this.userRole.isAgency() && !this.effectiveRole.isTechnician();
  }

  get isAgencyOwnTechnician(): boolean {
    return this.userRole.isAgency() && this.effectiveRole.isTechnician();
  }

  get isPackageOffer(): boolean {
    return this.offerType === OfferType.FIXED_PRICE;
  }

  get isTemporaryJobOpening(): boolean {
    return this.offerType === OfferType.TEMPORARY;
  }

  get isPermanentJobOpening(): boolean {
    return this.offerType === OfferType.PERMANENT;
  }

  private enableFilter(filterKey: string[]): void {
    this.updateApplicationsFilter(filterKey[1]);
    this.resetFilters();
    this.filters[filterKey[0]] = true; //QuickFilters
    this.filters[filterKey[1]] = true; // Applications
    this.store.dispatch(SetActiveFilter({ activeFilter: filterKey }));
    this.triggerListLoad();
  }

  private updateApplicationsFilter(filterKey: string): void {
    this.currentApplicationsFilter = { filter: filterKey, label: null };
  }

  private resetFilters(): void {
    const { all, active, closedForApplications, expired } = JobOpeningFiltersConstants.quickFilter;

    const {
      allApplications,
      activeTechnicianApplicationsFilter,
      pendingApplicationsFilter,
      documentsNeededApplicationsFilter,
      rejectedApplicationsFilter
    } = JobOpeningFiltersConstants.applicationFilter;

    this.filters = {
      [all]: false,
      [active]: false,
      [closedForApplications]: false,
      [expired]: false,
      [allApplications]: false,
      [activeTechnicianApplicationsFilter]: false,
      [pendingApplicationsFilter]: false,
      [documentsNeededApplicationsFilter]: false,
      [rejectedApplicationsFilter]: false
    };
  }

  private triggerListLoad(): void {
    if (this.actingAs) {
      this.store.dispatch(LoadListBO());
    } else {
      this.store.dispatch(LoadList({ role: this.effectiveRole.getRole() as any }));
    }
  }

  get canCreatePackageOffers(): boolean {
    return this.isPackageOffer && this.apiRoot.allowsPackageOffers;
  }

  get canCreatePermanentOffers(): boolean {
    return this.isPermanentJobOpening && this.apiRoot.allowsPermanentJobOffers;
  }

  private initializeMroFacilitiesObservable(): void {
    this.entityList$ = this.entitySearchObservable.pipe(
      debounceTime(250),
      tap(() => (this.loading = true)),
      withLatestFrom(this.store.pipe(getFilteredApiRoot)),
      switchMap(([{ term, pageSize, page, facilities, offerType }, apiRoot]) =>
        this.mroLoaderService
          .getMroFacilities(getUrl(apiRoot, ApiRootLinkRel.GetMROFacilitiesToFilter), term, page, pageSize, {
            offerType: offerType || OfferType.TEMPORARY
          })
          .pipe(
            map(retrievedFacilities => [
              ...facilities,
              ...retrievedFacilities._embedded.facilities.map(facility => ({
                uuid: facility.uuid,
                name: facility.name
              }))
            ]),
            tap(() => (this.loading = false)),
            tap(facilities => (this.hasMros = term ? this.hasMros : facilities?.length > 1))
          )
      )
    );
  }

  private watchQueryParams(): void {
    this.subscription.add(this.activatedRoute.queryParams.subscribe(params => this.updateFromQueryParams(params)));
  }

  private updateFromQueryParams(params: Params): void {
    if (!this.isPlatformWeb) {
      this.offerList = [];
    }
    this.refNumberSearch = params.refNumber ?? null;
    this.offerType = params.offerType ?? OfferType.TEMPORARY;
    const page = params.page || this.defaultPage;
    const filter: string[] = params.filter || [this.defaultFilter, this.defaultApplicationsFilter];
    this.store.dispatch(SetRefNumber({ refNumber: this.refNumberSearch }));
    this.selectedFacilityUuid = params.facilityUuid || null;
    this.store.dispatch(SetFacilityUuid({ facilityUuid: this.selectedFacilityUuid }));
    this.store.dispatch(SetFilterPage({ page: page - 1 }));
    this.store.dispatch(SetOfferType({ offerType: this.offerType }));
    this.enableFilter(filter);

    const currentSearchObservable = this.entitySearchObservable.getValue();
    if (params.offerType != currentSearchObservable.offerType) {
      this.entitySearchObservable.next({
        ...currentSearchObservable,
        offerType: this.offerType
      });
    }
  }

  private setupStoreSubscriptions(): void {
    this.storeSubscribe(select(selectPage), page => (this.page = page + 1));
    this.storeSubscribe(select(selectPageSize), pageSize => (this.pageSize = pageSize));
    this.storeSubscribe(select(selectTotalElements), totalElements => (this.totalElements = totalElements));
    this.storeSubscribe(select(selectResultList), resultList => {
      if (this.isPlatformWeb) {
        this.offerList = resultList;
      } else {
        if (this.offerList.length > 0) {
          this.offerList.push(...resultList);
        } else {
          this.offerList = cloneDeep(resultList);
        }
        this.isLastPage = this.page * this.pageSize >= this.totalElements;
      }
    });
    this.storeSubscribe(getFilteredApiRoot, apiRoot => {
      this.userRole = role(getEmbeddedResource(apiRoot, ApiRootLinkRel.AuthorizedUserProfile)['role']);
      this.setEffectiveRole(this.actingAs || this.userRole.getRole());
    });
    this.storeSubscribe(select(selectSelectedEntityUuid), uuid => {
      this.selectedTechnicianUuid = uuid;
      if (this.actingAs) {
        this.store.dispatch(LoadListBO());
      }
    });
    this.storeSubscribe(getFilteredApiRoot, apiRoot => {
      this.apiRoot = cloneDeep(apiRoot);
    });
  }
  private setupActionsSubscriptions(): void {
    this.onAction(LoadList, () => (this.isLoading = true));
    this.onAction(FailedToLoadList, () => (this.offerList = []));
    this.onAction(
      SuccessfulDeletedOffer,
      OfferSubmitSuccess,
      SuccessfulDeletedAgencyOffer,
      AgencyOfferSubmitSuccess,
      () => this.triggerListLoad()
    );
    this.onAction(OfferLoaded, ListLoaded, FailedToLoadList, () => (this.isLoading = false));
  }

  private storeSubscribe<T, S>(pipedSelector: OperatorFunction<T, S>, subscribeFn: (a: S) => void) {
    this.subscription.add(this.store.pipe(pipedSelector).subscribe(subscribeFn));
  }

  private onAction(...args: any[]): void {
    const subscribeFn = args.pop();
    this.subscription.add(this.actions.pipe(ofType(...args)).subscribe(subscribeFn));
  }
}
