import { createFormStateReducerWithUpdate, FormGroupState, setErrors, updateGroup, validate } from 'ngrx-forms';
import { greaterThan, lessThanOrEqualTo, maxLength, minLength, required } from 'ngrx-forms/validation';

import { CreateOfferFormModel, createOfferInitialFormState, FormState } from './create-offer.model';
import { ResetOfferForm } from './create-offer.actions';
import { getCustomReferenceValidator } from '../../../shared/validators/custom-reference.validator';
import { Action } from '@ngrx/store';

const maxYearsOfExperience: number = 60;
export const JO_PRESENTATION_MAX_LENGTH: number = 20000;
export const JO_TITLE_MIN_LENGTH: number = 6;
export const JO_TITLE_MAX_LENGTH: number = 60;
export const otherAirplanesMaxLength: number = 120;
export const workDaysMaxValue: number = 99;
export const chargeRateMaxValue: number = 999;

export const CREATE_EDIT_OFFER_FEATURE_KEY = 'createEditOffer';

const validateAircraft = (state: FormState = createOfferInitialFormState): FormGroupState<CreateOfferFormModel> => {
  if (state.controls.airplanes.value.length !== 0) {
    return updateGroup<CreateOfferFormModel>({
      otherAirplanes: setErrors(null)
    })(state);
  } else if (state.controls.otherAirplanes.value) {
    return updateGroup<CreateOfferFormModel>({
      airplanes: setErrors(null)
    })(state);
  }

  return state;
};

const validateMaxChargeRate = (
  state: FormState = createOfferInitialFormState
): FormGroupState<CreateOfferFormModel> => {
  if (state.controls.hasIndicativeRate.value) {
    return updateGroup<CreateOfferFormModel>({
      maxChargeRate: validate(
        required,
        greaterThan(-1),
        lessThanOrEqualTo(chargeRateMaxValue),
        greaterThan(state.controls.chargeRate.value ?? 0)
      )
    })(state);
  } else {
    return updateGroup<CreateOfferFormModel>({
      maxChargeRate: setErrors(null)
    })(state);
  }
};

const updateMyFormGroup = updateGroup<CreateOfferFormModel>({
  refNumber: getCustomReferenceValidator(),
  shortPresentation: validate<string>(required, minLength(2), maxLength(JO_PRESENTATION_MAX_LENGTH)),
  periodFrom: validate(required),
  periodTo: validate(required),
  vacancies: validate(required, greaterThan(0), lessThanOrEqualTo(1000)),
  ameType: validate(required),
  ameTitleId: validate(required),
  airplanes: validate(required),
  minExperience: validate(required, greaterThan(-1), lessThanOrEqualTo(maxYearsOfExperience)),
  chargeRate: validate(required, greaterThan(-1), lessThanOrEqualTo(chargeRateMaxValue)),
  workDaysOn: validate(required, greaterThan(0), lessThanOrEqualTo(workDaysMaxValue)),
  workDaysOff: validate(required, greaterThan(-1), lessThanOrEqualTo(workDaysMaxValue)),
  otherAirplanes: validate(required),
  locationId: validate(required),
  priority: validate(required),
  title: validate<string>((inputText: string) => validateTitle(inputText))
});

export function validateTitle(inputText: string) {
  if (!inputText || (inputText.length >= JO_TITLE_MIN_LENGTH && inputText.length <= JO_TITLE_MAX_LENGTH)) {
    return null;
  }
  return { invalidTitleLength: true };
}

const formReducer = createFormStateReducerWithUpdate(updateMyFormGroup);

export function createOfferFormReducer(
  state: FormState = createOfferInitialFormState,
  action: Action
): FormGroupState<CreateOfferFormModel> {
  let form = formReducer(state, action);
  form = validateAircraft(form);
  form = validateMaxChargeRate(form);

  if (action.type === ResetOfferForm.type) {
    return {
      ...createOfferInitialFormState
    };
  }
  return {
    ...form
  };
}
