import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

import { TranslationModule } from '@libs/translation/translation.module';

import { RequestListingComponent } from './components/request-listing/request-listing.component';
import { RequestOverviewAsTechnician } from './components/request-overview-as-technician/request-overview-as-technician.component';
import { MroRequestCardComponent } from './components/request-card/mro-request-card.component';
import { MroJobOfferDetailsComponent } from './components/offer-details/facility-job-offer-details/mro-job-offer-details.component';
import { MroContractCardComponent } from './components/contract-card/mro-contract-card.component';
import { TechnicianRequestCardComponent } from './components/request-card/technician-request-card.component';
import { TechnicianOfferDetailsComponent } from './components/offer-details/technician-offer-details/technician-offer-details.component';
import { AgencyRequestCardComponent } from './components/request-card/agency-request-card.component';
import { AgencyJobOfferDetailsComponent } from './components/offer-details/facility-job-offer-details/agency-job-offer-details.component';
import { AgencyContractCardComponent } from './components/contract-card/agency-contract-card.component';
import { OwnTechnicianOfferDetailsComponent } from './components/offer-details/technician-offer-details/own-technician-offer-details.component';
import { TechnicianReasonsDetailsComponent } from './components/technician-reasons-details/technician-reasons-details.component';
import { ContractCardWithHeadComponent } from './components/contract-card/contract-card-with-head.component';
import { OfferDetailsComponent } from './components/offer-details/offer-details.component';
import { CollapsibleContractsListComponent } from './components/collapsible-contracts-list/collapsible-contracts-list.component';
import { RequestOverviewEffects } from './state/request-overview.effects';
import { initialState, REQUEST_OVERVIEW_FEATURE_KEY } from './state/request-overview-state.model';
import { getReducer } from './state/request-overview.reducer';
import { ContractInfoComponent } from './components/contract-info/contract-info.component';
import { ReasonsListComponent } from './components/reasons-list/reasons-list.component';
import { CommonUiModule } from '@libs/common-ui/common-ui.module';
import { RequestOverviewCommonModule } from '@libs/request-overview-common/request-overview-common.module';
import { AgencyModalModule } from '@libs/agencies-list-modal/agency-modal.module';
import { SharedModule } from '@libs/shared/shared.module';
import { RatePipe } from '@libs/shared/pipes/rate.pipe';
import { ExperienceLetterEditionComponent } from './components/experience-letter-edition/experience-letter-edition.component';
import { CollapsibleContractsContainerComponent } from './components/collapsible-contracts-container/collapsible-contracts-container.component';
import { TechnicianGuidelinesComponent } from './components/technician-guidelines/technician-guidelines.component';
import { StaffFiltersModule } from '@libs/staff-filters/staff-filters.module';
import { RequestOverviewWrapperComponent } from './components/request-overview-wrapper/request-overview-wrapper.component';
import { MroPackageOfferDetailsComponent } from './components/offer-details/mro-package-offer-details/mro-package-offer-details.component';
import { AgencyPackageOfferDetailsComponent } from './components/offer-details/agency-package-offer-details/agency-package-offer-details.component';
import { CollapsiblePackageContractsContainerComponent } from './components/collapsible-package-contracts-container/collapsible-package-contracts-container.component';
import { PackageContractCardComponent } from './components/package-contract-card/package-contract-card.component';
import { PackageAgencyCardComponent } from './components/package-agency-selection/package-agency-card/package-agency-card.component';
import { PackageAgencySelectionComponent } from './components/package-agency-selection/package-agency-selection.component';
import { TechnicianPackageOfferDetailsComponent } from './components/offer-details/technician-package-offer-details/technician-package-offer-details.component';
import { AgencyOfferDetailsComponent } from './components/offer-details/agency-offer-details/agency-offer-details.component';
import { AgencyOfferContractCardWithHeadComponent } from './components/collapsible-agency-offer-contracts-container/agency-offer-contract-card-with-head.component';
import { AgencyOfferContractCardComponent } from './components/collapsible-agency-offer-contracts-container/agency-offer-contract-card.component';
import { CollapsibleAgencyOfferContractsContainerComponent } from './components/collapsible-agency-offer-contracts-container/collapsible-agency-offer-contracts-container.component';
import { CollapsibleAgencyOfferContractsListComponent } from './components/collapsible-agency-offer-contracts-container/collapsible-agency-offer-contracts-list.component';
import { TechnicianAgencyOfferDetailsComponent } from './components/offer-details/agency-offer-details/technician-agency-offer-details.component';
import { TechnicianOfferPayRateComponent } from './components/offer-details/technician-offer-details/technician-offer-pay-rate/technician-offer-pay-rate-component';
import { MroPermanentJobOpeningDetailsComponent } from './components/offer-details/mro-permanent-job-opening-details/mro-permanent-job-opening-details.component';
import { PackageOfferModule } from '@libs/package-offer/package-offer.module';
import { TechnicianPermanentOfferDetailsComponent } from './components/offer-details/mro-permanent-job-opening-details/technician-permanent-offer-details.component';
import { TechnicianContractDetailsComponent } from './components/offer-details/technician-offer-details/technician-contract-details/technician-contract-details.component';
import { TechnicianContractActionsComponent } from './components/offer-details/technician-offer-details/technician-contract-actions/technician-contract-actions.component';
import { ReadMoreComponent } from '@libs/common-ui/read-more/read-more.component';
import { WorkPatternDisplayComponent } from '@libs/common-ui/work-pattern-display/work-pattern-display.component';
import { TechnicianPaginatedSelectorComponent } from '@libs/common-ui/technician-paginated-selector/technician-paginated-selector.component';
import { FiltersModule } from '../filters/filters.module';
import { OfferListingComponent } from './components/offer-listing/offer-listing.component';
import { OfferActionsComponent } from './components/offer-actions/offer-actions.component';
import { ContractsListJoExpandComponent } from './components/contracts-list-jo-expand/contracts-list-jo-expand.component';
import { ContractActionTableComponent } from './components/contract-action-table/contract-action-table.component';

@NgModule({
  imports: [
    CommonModule,
    CommonUiModule,
    TranslationModule,
    PaginationModule.forRoot(),
    TooltipModule.forRoot(),
    BsDropdownModule.forRoot(),
    FormsModule,
    SharedModule,
    RequestOverviewCommonModule,
    AgencyModalModule,
    StaffFiltersModule,
    StoreModule.forFeature(REQUEST_OVERVIEW_FEATURE_KEY, getReducer(), {
      initialState
    }),
    EffectsModule.forFeature([RequestOverviewEffects]),
    PackageOfferModule,
    ReadMoreComponent,
    WorkPatternDisplayComponent,
    TechnicianPaginatedSelectorComponent,
    FiltersModule
  ],
  providers: [RatePipe],
  declarations: [
    RequestListingComponent,
    RequestOverviewAsTechnician,
    MroRequestCardComponent,
    MroJobOfferDetailsComponent,
    MroPermanentJobOpeningDetailsComponent,
    AgencyOfferDetailsComponent,
    TechnicianAgencyOfferDetailsComponent,
    TechnicianPermanentOfferDetailsComponent,
    MroContractCardComponent,
    TechnicianRequestCardComponent,
    TechnicianOfferDetailsComponent,
    AgencyRequestCardComponent,
    AgencyJobOfferDetailsComponent,
    AgencyContractCardComponent,
    TechnicianOfferPayRateComponent,
    OwnTechnicianOfferDetailsComponent,
    TechnicianReasonsDetailsComponent,
    ContractCardWithHeadComponent,
    OfferDetailsComponent,
    CollapsibleContractsListComponent,
    CollapsibleContractsContainerComponent,
    ContractInfoComponent,
    ReasonsListComponent,
    ExperienceLetterEditionComponent,
    TechnicianGuidelinesComponent,
    RequestOverviewWrapperComponent,
    MroPackageOfferDetailsComponent,
    AgencyPackageOfferDetailsComponent,
    CollapsiblePackageContractsContainerComponent,
    PackageContractCardComponent,
    PackageAgencyCardComponent,
    PackageAgencySelectionComponent,
    TechnicianPackageOfferDetailsComponent,
    AgencyOfferContractCardWithHeadComponent,
    AgencyOfferContractCardComponent,
    CollapsibleAgencyOfferContractsContainerComponent,
    CollapsibleAgencyOfferContractsListComponent,
    TechnicianContractDetailsComponent,
    TechnicianContractActionsComponent,
    OfferListingComponent,
    OfferActionsComponent,
    ContractsListJoExpandComponent,
    ContractActionTableComponent
  ],
  exports: [RequestOverviewAsTechnician, RequestListingComponent, ContractInfoComponent]
})
export class RequestsOverviewModule {}
