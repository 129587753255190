import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';

import { FileUploadModule } from 'ng2-file-upload';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { NgSelectModule } from '@ng-select/ng-select';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';

import { TranslationModule } from '@libs/translation/translation.module';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { FileListComponent } from './file-upload/file-list/file-list.component';
import { HeaderComponent } from './header/header.component';
import { NavbarMobileComponent } from './navbar-mobile/navbar-mobile.component';
import { NavbarComponent } from './navbar/navbar.component';
import { ModalWindowComponent } from './modal-window/modal-window.component';
import { TriStateSwitchComponent } from '@libs/common-ui/switches/tri-state-switch/tri-state-switch.component';
import { StaffLoaderComponent } from './staff-loader/staff-loader.component';
import { FailAnimationComponent } from './fail-animation/fail-animation.component';
import { SuccessAnimationComponent } from './success-animation/success-animation.component';
import { LoadingContainerComponent } from './loading-container/loading-container.component';
import { GenericModalComponent } from './modals/generic-modal/generic-modal.component';
import { CalendarComponent } from './calendar/calendar.component';
import { NotFoundPageComponent } from './not-found-page/not-found-page.component';
import { ContactsComponent } from './contacts/contacts.component';
import { FacilityProfilePageComponent } from './facility-profile/facility-profile-page.component';
import { FacilityPictureUploadComponent } from './facility-picture-upload/facility-picture-upload.component';
import { StaffLogoComponent } from './staff-logo/staff-logo.component';
import { FacilityCoverPlaceholderComponent } from './facility-cover-placeholder/facility-cover-placeholder.component';
import { FacilityLogoComponent } from './facility-logo/facility-logo.component';
import { CloseOnOutsideDirective } from './directives/close-on-outside.directive';
import { OfferModalComponent } from './offer-modal/offer-modal.component';
import { TechnicianProfileModalComponent } from './technician-profile-modal/technician-profile-modal.component';
import { FacilityProfileModalComponent } from './facility-profile-modal/facility-profile-modal.component';
import { SelectEntitiesFromListModalComponent } from './select-entities-from-list-modal/select-entities-from-list-modal.component';
import { TabNavigationComponent } from './tab-navigation/tab-navigation.component';
import { TechniciansListModalComponent } from './technicians-list-modal/technicians-list-modal.component';
import { TechnicianCardComponent } from './technician-card/technician-card.component';
import { NotificationSubscriptionComponent } from './notification-subscription/notification-subscription.component';
import { OfferAircraftFieldComponent } from './offer-aircrafts-field/offer-aircraft-field.component';
import { TypeaheadFilterComponent } from './typeahead-filter/typeahead-filter.component';
import { SimpleSelectComponent } from './simple-select/simple-select.component';
import { TechnicianTitleListComponent } from './technician-title-list/technician-title-list.component';
import { ApprovedAgenciesSelectComponent } from './facility-profile/approved-agencies-select/approved-agencies-select.component';
import { MroApprovalsComponent } from './facility-profile/mro-approvals/mro-approvals.component';
import { MarkupsComponent } from './facility-profile/markups/markups.component';
import { StatusCircleComponent } from './status-circle/status-circle.component';
import { ContractStatusComponent } from './contract-status/contract-status.component';
import { QualityRejectComponent } from './quality-reject/quality-reject.component';
import { QualityAcceptComponent } from './quality-accept/quality-accept.component';
import { CornerCardComponent } from './corner-card-text/corner-card.component';
import { CollapsibleComponent } from './collapsible/collapsible.component';
import { InfoIconWithTooltipComponent } from './info-icon-with-tooltip/info-icon-with-tooltip.component';
import { OfferBenefitDetailsComponent } from './offer-benefit-details/offer-benefit-details.component';
import { JobOfferInfoComponent } from './job-offer-info/job-offer-info.component';
import { ReasonListItemComponent } from './reason-list-item/reason-list-item.component';
import { CustomerSupportModalComponent } from './customer-support-error-modal/customer-support-modal.component';
import { OfferInfoSectionComponent } from './offer-info-section/offer-info-section.component';
import { SelectedAircraftPipe } from '@libs/shared/pipes/selected-aircrafts.pipe';
import { SharedModule } from '@libs/shared/shared.module';
import { OfferPriorityInfoComponent } from '@libs/common-ui/offer-priority-info/offer-priority-info.component';
import { QuestionModalComponent } from '@libs/common-ui/modals/question-modal/question-modal.component';
import { ContractDateActionsComponent } from '@libs/common-ui/contract-date-actions/contract-date-actions.component';
import { FacilityContractDateComponent } from '@libs/common-ui/facility-contract-date/facility-contract-date.component';
import { ProductionRejectComponent } from '@libs/common-ui/production-reject/production-reject.component';
import { SelectWithTabsComponent } from '@libs/common-ui/select-with-tabs/select-with-tabs.component';
import { CardTabTextComponent } from '@libs/common-ui/card-tab-text/card-tab-text.component';
import { DocumentsNeededModalComponent } from '@libs/common-ui/documents-needed-modal/documents-needed-modal.component';
import { ShareOfferComponent } from '@libs/common-ui/share-offer-component/share-offer-component';
import { InformationPanelComponent } from '@libs/common-ui/information-panel/information-panel.component';
import { QuillModule } from 'ngx-quill';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { ImageUploaderComponent } from '@libs/common-ui/facility-profile/image-uploader/image-uploader.component';
import { TextEditorComponent } from '@libs/common-ui/facility-profile/text-editor/text-editor.component';
import { InformationBannerComponent } from '@libs/common-ui/information-banner/information-banner.component';
import { FileUpload2Component } from '@libs/common-ui/file-upload-2/file-upload-2.component';
import { FacilityStatusComponent } from '@libs/common-ui/facility-profile/facility-status/facility-status.component';
import { AlertBannerComponent } from '@libs/common-ui/alert-banner/alert-banner.component';
import { PromotionalAgencyListComponent } from '@libs/common-ui/promotional-agencies/promotional-agency-list/promotional-agency-list.component';
import { PromotionalAgencyCardComponent } from '@libs/common-ui/promotional-agencies/promotional-agency-card/promotional-agency-card.component';
import { TextCounterComponent } from '@libs/common-ui/text-counter/text-counter.component';
import { FacilityProfilePreviewComponent } from '@libs/common-ui/facility-profile/facility-profile-preview/facility-profile-preview.component';
import { CounterBadge } from './counter-badge/counter-badge.component';
import { RatesTableComponent } from '@libs/common-ui/facility-profile/rates-table/rates-table.component';
import { TextAreaDialogComponent } from './text-area-dialog/text-area-dialog.component';
import { CreateOfferBannerComponent } from './create-offer-banner/create-offer-banner.component';
import { PackageOfferInfoComponent } from '@libs/common-ui/package-offer-info/package-offer-info.component';
import { PackageContractStatusComponent } from './package-contract-status/package-contract-status.component';
import { CreatePackageContractFormComponent } from './create-package-contract-form/create-package-contract-form.component';
import { TechnicianPictureAndNameComponent } from './technician-picture-and-name/technician-picture-and-name.component';
import { ChipComponent } from './chip/chip.component';
import { PackageContractRejectionComponent } from './package-contract-rejection/package-contract-rejection.component';
import { NumberPickerDialogComponent } from './number-picker-dialog/number-picker-dialog.component';
import { RatesHistoryComponent } from './rates-history/rates-history.component';
import { LabelSetterDialogComponent } from './label-setter-dialog/label-setter-dialog.component';
import { FileListDialogComponent } from './file-upload/file-list-dialog/file-list-dialog.component';
import { SelectEntityFromListModalComponent } from './select-entity-from-list-modal/select-entity-from-list-modal.component';
import { AgencyOfferContractReasonsListComponent } from './agency-offer-contract-reasons-list/agency-offer-contract-reasons-list.component';
import { LoginFormComponent } from './login-form/login-form.component';
import { UnauthorizedPageComponent } from './unauthorized-page/unauthorized-page.component';
import { BreakDatesPickerDialogComponent } from './break-dates-picker-dialog/break-dates-picker-dialog.component';
import { MultipleOptionsSelectComponent } from './multiple-options-select/multiple-options-select.component';
import { ChangePasswordComponent } from './password-management/change-password/change-password.component';
import { RequestChangePasswordComponent } from './password-management/request-change-password/request-change-password.component';
import { QuicksightViewer } from '@libs/common-ui/quicksight-viewer/quicksight-viewer.component';
import { WeeklyJoSummarySubscriptionComponent } from './weekly-jo-summary-subscription/weekly-jo-summary-subscription.component';
import { UpdateAppComponent } from '@libs/common-ui/update-app/update-app.component';
import { StaffnowTextareaComponent } from '@libs/common-ui/staffnow-textarea/staffnow-textarea.component';
import { ToggleSwitchComponent } from '@libs/common-ui/switches/toggle-switch/toggle-switch.component';
import { DatePickerDialogComponent } from '@libs/common-ui/pickers/modal/date-picker-dialog/date-picker-dialog.component';
import { DatePickerSingleComponent } from '@libs/common-ui/pickers/date/date-picker-single/date-picker-single.component';
import { DatePickerRangeComponent } from '@libs/common-ui/pickers/date/date-picker-range/date-picker-range.component';
import { DatePickerInputWritableComponent } from '@libs/common-ui/pickers/date/date-picker-input-writable/date-picker-input-writable.component';
import { TimePickerComponent } from '@libs/common-ui/pickers/time/time-picker/time-picker.component';
import { SingleDateAndTimePickerComponent } from '@libs/common-ui/pickers/date-time/single-date-and-time-picker/single-date-and-time-picker.component';
import { ButtonsModule } from '@libs/common-ui/buttons/buttons.module';
import { FileUploadService } from './services/file-upload.service';
import { ModalService } from './services/modal.service';
import { ErrorMessageService } from '@libs/common-ui/services/error-message/error-message.service';
import { AgencyRejectComponent } from '@libs/common-ui/agency-reject/agency-reject.component';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { ReadMoreComponent } from '@libs/common-ui/read-more/read-more.component';
import { WorkPatternDisplayComponent } from '@libs/common-ui/work-pattern-display/work-pattern-display.component';
import { CountryModule } from '@libs/common-ui/country/country.module';
import { TechnicianPaginatedSelectorComponent } from '@libs/common-ui/technician-paginated-selector/technician-paginated-selector.component';
import { StatusOfferIconComponent } from '@libs/common-ui/status-offer-icon/status-offer-icon.component';
import { OfferStartDateComponent } from '@libs/common-ui/offer-start-date/offer-start-date.component';
import { OfferEndDateComponent } from '@libs/common-ui/offer-end-date/offer-end-date.component';
import { DateInformationAlertComponent } from '@libs/common-ui/date-information-alert/date-information-alert.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    TranslationModule,
    FileUploadModule,
    ModalModule.forRoot(),
    TooltipModule.forRoot(),
    BsDatepickerModule.forRoot(),
    FormsModule,
    NgSelectModule,
    BsDropdownModule.forRoot(),
    TypeaheadModule,
    QuillModule.forRoot(),
    CarouselModule,
    SharedModule,
    ButtonsModule,
    StaffLoaderComponent,
    ProgressbarModule,
    CountryModule,
    ReadMoreComponent,
    WorkPatternDisplayComponent,
    StatusCircleComponent,
    TechnicianPaginatedSelectorComponent
  ],
  declarations: [
    FailAnimationComponent,
    SuccessAnimationComponent,
    HeaderComponent,
    NavbarComponent,
    NavbarMobileComponent,
    FileUploadComponent,
    FileUpload2Component,
    FileListDialogComponent,
    FileListComponent,
    ModalWindowComponent,
    TriStateSwitchComponent,
    LoadingContainerComponent,
    GenericModalComponent,
    QuestionModalComponent,
    CalendarComponent,
    NotFoundPageComponent,
    UpdateAppComponent,
    ContactsComponent,
    FacilityProfilePageComponent,
    ApprovedAgenciesSelectComponent,
    RatesTableComponent,
    MroApprovalsComponent,
    MarkupsComponent,
    ImageUploaderComponent,
    TextEditorComponent,
    FacilityPictureUploadComponent,
    StaffLogoComponent,
    FacilityCoverPlaceholderComponent,
    FacilityLogoComponent,
    CloseOnOutsideDirective,
    OfferModalComponent,
    OfferAircraftFieldComponent,
    TechnicianProfileModalComponent,
    FacilityProfileModalComponent,
    SelectEntitiesFromListModalComponent,
    SelectEntityFromListModalComponent,
    TabNavigationComponent,
    TechniciansListModalComponent,
    TechnicianCardComponent,
    TypeaheadFilterComponent,
    NotificationSubscriptionComponent,
    WeeklyJoSummarySubscriptionComponent,
    SimpleSelectComponent,
    SelectWithTabsComponent,
    TechnicianTitleListComponent,
    ContractStatusComponent,
    QualityRejectComponent,
    QualityAcceptComponent,
    CornerCardComponent,
    CollapsibleComponent,
    InfoIconWithTooltipComponent,
    OfferBenefitDetailsComponent,
    JobOfferInfoComponent,
    PackageOfferInfoComponent,
    ReasonListItemComponent,
    CustomerSupportModalComponent,
    OfferInfoSectionComponent,
    OfferPriorityInfoComponent,
    ContractDateActionsComponent,
    DatePickerDialogComponent,
    LabelSetterDialogComponent,
    FacilityContractDateComponent,
    ProductionRejectComponent,
    CardTabTextComponent,
    DocumentsNeededModalComponent,
    ShareOfferComponent,
    InformationPanelComponent,
    InformationBannerComponent,
    FacilityStatusComponent,
    FacilityProfilePreviewComponent,
    AlertBannerComponent,
    PromotionalAgencyCardComponent,
    PromotionalAgencyListComponent,
    TextCounterComponent,
    CounterBadge,
    TextAreaDialogComponent,
    CreateOfferBannerComponent,
    PackageContractStatusComponent,
    CreatePackageContractFormComponent,
    TechnicianPictureAndNameComponent,
    ChipComponent,
    PackageContractRejectionComponent,
    NumberPickerDialogComponent,
    RatesHistoryComponent,
    AgencyOfferContractReasonsListComponent,
    DatePickerSingleComponent,
    DatePickerRangeComponent,
    DatePickerInputWritableComponent,
    LoginFormComponent,
    UnauthorizedPageComponent,
    BreakDatesPickerDialogComponent,
    MultipleOptionsSelectComponent,
    ChangePasswordComponent,
    RequestChangePasswordComponent,
    QuicksightViewer,
    StaffnowTextareaComponent,
    ToggleSwitchComponent,
    TimePickerComponent,
    SingleDateAndTimePickerComponent,
    AgencyRejectComponent,
    StatusOfferIconComponent,
    OfferStartDateComponent,
    OfferEndDateComponent,
    DateInformationAlertComponent
  ],
  exports: [
    StaffLoaderComponent,
    FailAnimationComponent,
    SuccessAnimationComponent,
    HeaderComponent,
    NavbarComponent,
    NavbarMobileComponent,
    FileUploadComponent,
    FileUpload2Component,
    FileListComponent,
    FileListDialogComponent,
    ModalWindowComponent,
    TriStateSwitchComponent,
    LoadingContainerComponent,
    GenericModalComponent,
    QuestionModalComponent,
    CalendarComponent,
    NotFoundPageComponent,
    UpdateAppComponent,
    ContactsComponent,
    FacilityProfilePageComponent,
    FacilityPictureUploadComponent,
    StaffLogoComponent,
    FacilityCoverPlaceholderComponent,
    FacilityLogoComponent,
    CloseOnOutsideDirective,
    OfferModalComponent,
    OfferAircraftFieldComponent,
    TechnicianProfileModalComponent,
    SelectEntitiesFromListModalComponent,
    SelectEntityFromListModalComponent,
    TooltipModule,
    TabNavigationComponent,
    FacilityProfileModalComponent,
    TechniciansListModalComponent,
    TechnicianCardComponent,
    FacilityProfileModalComponent,
    NotificationSubscriptionComponent,
    WeeklyJoSummarySubscriptionComponent,
    TypeaheadFilterComponent,
    SimpleSelectComponent,
    SelectWithTabsComponent,
    TechnicianTitleListComponent,
    ContractStatusComponent,
    QualityRejectComponent,
    QualityAcceptComponent,
    CollapsibleComponent,
    CornerCardComponent,
    InfoIconWithTooltipComponent,
    OfferBenefitDetailsComponent,
    JobOfferInfoComponent,
    PackageOfferInfoComponent,
    ReasonListItemComponent,
    CustomerSupportModalComponent,
    OfferInfoSectionComponent,
    OfferPriorityInfoComponent,
    ContractDateActionsComponent,
    DatePickerDialogComponent,
    LabelSetterDialogComponent,
    FacilityContractDateComponent,
    ProductionRejectComponent,
    CardTabTextComponent,
    DocumentsNeededModalComponent,
    ShareOfferComponent,
    InformationPanelComponent,
    InformationBannerComponent,
    AlertBannerComponent,
    PromotionalAgencyListComponent,
    TextCounterComponent,
    CounterBadge,
    TextAreaDialogComponent,
    CreateOfferBannerComponent,
    PackageContractStatusComponent,
    CreatePackageContractFormComponent,
    TechnicianPictureAndNameComponent,
    ChipComponent,
    RatesHistoryComponent,
    AgencyOfferContractReasonsListComponent,
    DatePickerSingleComponent,
    DatePickerRangeComponent,
    DatePickerInputWritableComponent,
    LoginFormComponent,
    UnauthorizedPageComponent,
    BreakDatesPickerDialogComponent,
    MultipleOptionsSelectComponent,
    ChangePasswordComponent,
    RequestChangePasswordComponent,
    QuicksightViewer,
    StaffnowTextareaComponent,
    ToggleSwitchComponent,
    TimePickerComponent,
    SingleDateAndTimePickerComponent,
    ButtonsModule,
    AgencyRejectComponent,
    StatusOfferIconComponent,
    OfferStartDateComponent,
    OfferEndDateComponent,
    DateInformationAlertComponent
  ],
  providers: [FileUploadService, ModalService, ErrorMessageService, SelectedAircraftPipe]
})
export class CommonUiModule {}
