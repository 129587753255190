import { createFormStateReducerWithUpdate, FormGroupState, updateGroup, validate } from 'ngrx-forms';
import { maxLength, minLength, required } from 'ngrx-forms/validation';

import { CreatePackageOfferFormModel, FormState, initialPackageOfferFormState } from './create-package-offer.model';
import { ResetForm } from './package-offer.actions';
import { getCustomReferenceValidator } from '../../../../shared/validators/custom-reference.validator';
import { Action } from '@ngrx/store';
import { validateTitle } from '@libs/create-offer-page/create-offer.reducer';

export const CREATE_EDIT_PACKAGE_OFFER_FEATURE_KEY = 'createEditPackageOffer';
export const FPO_SPECIFICATION_MAX_LENGTH: number = 20000;

const updateMyFormGroup = updateGroup<CreatePackageOfferFormModel>({
  refNumber: getCustomReferenceValidator(),
  specification: validate<string>(required, minLength(2), maxLength(FPO_SPECIFICATION_MAX_LENGTH)),
  preferredStartDate: validate(required),
  preferredEndDate: validate(required),
  locationId: validate(required),
  priority: validate(required),
  title: validate<string>((inputText: string) => validateTitle(inputText))
});

const formReducer = createFormStateReducerWithUpdate(updateMyFormGroup);

export function createPackageOfferFormReducer(
  state: FormState = initialPackageOfferFormState,
  action: Action
): FormGroupState<CreatePackageOfferFormModel> {
  const form = formReducer(state, action);

  if (action.type === ResetForm.type) {
    return {
      ...initialPackageOfferFormState
    };
  }
  return {
    ...form
  };
}
