interface TableColumn {
  name: string;
  key: string;
}

const PERIOD: TableColumn = {
  name: 'Period',
  key: 'period'
};

const PRIORITY: TableColumn = {
  name: 'Priority',
  key: 'priority'
};

const ACTIONS: TableColumn = {
  name: 'Actions',
  key: 'actions'
};

const COMMON: TableColumn[] = [
  {
    name: 'Ref. No',
    key: 'refNumber'
  },
  PERIOD
];

const TYPES_AND_PRIORITY: TableColumn[] = [
  PRIORITY,
  {
    name: 'Type of Technicians',
    key: 'ameType'
  },
  {
    name: 'Aircraft Type',
    key: 'aircraft'
  }
];

export const MRO_JOB_OPENING_CONFIG: TableColumn[] = [
  ...COMMON,
  {
    name: 'Number of Technicians',
    key: 'vacancies'
  },
  {
    name: 'Charge Rate',
    key: 'chargeRate'
  },
  {
    name: 'Pay Rate',
    key: 'payRate'
  },
  {
    name: 'Experience',
    key: 'minExperience'
  },
  ...TYPES_AND_PRIORITY,
  ACTIONS
];

export const MRO_PERMANENT_JOB_OPENING_CONFIG: TableColumn[] = [
  {
    name: 'Ref. No',
    key: 'refNumber'
  },
  {
    name: 'Start Date',
    key: 'startDate'
  },
  {
    name: 'Number of Technicians',
    key: 'vacancies'
  },
  {
    name: 'Net Salary',
    key: 'netSalary'
  },
  {
    name: 'Experience',
    key: 'minExperience'
  },
  ...TYPES_AND_PRIORITY,
  ACTIONS
];

export const MRO_FIXED_PRICE_JOB_OPENING_CONFIG: TableColumn[] = [...COMMON, PRIORITY, ACTIONS];

export const TECHNICIAN_CONFIG: TableColumn[] = [
  ...COMMON,
  {
    name: 'Aviation Company Name',
    key: 'mroName'
  },
  {
    name: 'Pay Rate',
    key: 'payRate'
  },
  ...TYPES_AND_PRIORITY,
  ACTIONS
];

export const TECHNICIAN_PERMANENT_JOB_OPENING_CONFIG: TableColumn[] = [
  {
    name: 'Ref. No',
    key: 'refNumber'
  },
  {
    name: 'Start Date',
    key: 'startDate'
  },
  {
    name: 'Aviation Company Name',
    key: 'mroName'
  },
  {
    name: 'Net Salary',
    key: 'netSalary'
  },
  ...TYPES_AND_PRIORITY,
  ACTIONS
];

export const AGENCY_FIXED_PRICE_JOB_OPENING_CONFIG: TableColumn[] = [
  ...COMMON,
  {
    name: 'Aviation Company Name',
    key: 'mroName'
  },
  PRIORITY,
  ACTIONS
];

export const TECHNICIAN_FIXED_PRICE_JOB_OPENING_CONFIG: TableColumn[] = [
  ...COMMON,
  {
    name: 'Aviation Company Name',
    key: 'mroName'
  },
  ACTIONS
];

export const AGENCY_AGENCY_JOB_OPENING_CONFIG: TableColumn[] = [
  ...COMMON,
  {
    name: 'Aviation Company Name',
    key: 'mroName'
  },
  PRIORITY,
  ACTIONS
];

export const TECHNICIAN_AGENCY_JOB_OPENING_CONFIG: TableColumn[] = [
  ...COMMON,
  {
    name: 'Aviation Company Name',
    key: 'mroName'
  },
  {
    name: 'Agency Name',
    key: 'agencyOfferAgencyName'
  },
  ACTIONS
];

export const AGENCY_CONFIG: TableColumn[] = [
  ...COMMON,
  {
    name: 'Aviation Company Name',
    key: 'mroName'
  },
  {
    name: 'Charge Rate',
    key: 'chargeRate'
  },
  {
    name: 'Pay Rate',
    key: 'payRate'
  },
  ...TYPES_AND_PRIORITY,
  ACTIONS
];

export const AVIATION_COMPANY_TEMPORARY_AND_PERMANENT_JOB_OPENING_CONFIG: TableColumn[] = [
  {
    name: 'Ref. Nº',
    key: 'refNumber'
  },
  {
    name: 'Status',
    key: 'status'
  },
  PRIORITY,
  PERIOD,
  {
    name: 'Type of Technician',
    key: 'ameType'
  },
  {
    name: 'Aircraft Type',
    key: 'aircraft'
  },
  {
    name: 'Vacancies',
    key: 'vacancies'
  },
  {
    name: 'Unfilled',
    key: 'unfilledVacancies'
  },
  {
    name: 'Accepted',
    key: 'fullyMatchedSize'
  }
];

export const AVIATION_COMPANY_TEMPORARY_JOB_OPENING_CONFIG: TableColumn[] = [
  ...AVIATION_COMPANY_TEMPORARY_AND_PERMANENT_JOB_OPENING_CONFIG,
  {
    name: 'Paused',
    key: 'pausedTechnicians'
  },
  {
    name: 'Documents Needed',
    key: 'documentsNeededContracts'
  },
  {
    name: 'Rejected',
    key: 'rejectedContracts'
  },
  {
    name: 'Finished',
    key: 'finishedContracts'
  },
  {
    name: 'On-site',
    key: 'onSiteTechnicians'
  },
  ACTIONS
];

export const AVIATION_COMPANY_PERMANENT_JOB_OPENING_CONFIG: TableColumn[] = [
  ...AVIATION_COMPANY_TEMPORARY_AND_PERMANENT_JOB_OPENING_CONFIG,
  {
    name: 'Documents Needed',
    key: 'documentsNeededContracts'
  },
  {
    name: 'Rejected',
    key: 'rejectedContracts'
  },
  {
    name: 'Finished',
    key: 'finishedContracts'
  },
  {
    name: 'On-site',
    key: 'onSiteTechnicians'
  },
  ACTIONS
];
