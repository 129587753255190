<ng-template [ngIf]="facilityProfile">
    <div class="facility-profile-container">
        <div class="profile-preview">
            <staffnow-facility-profile-preview [facilityProfile]="facilityProfile" [displayText]="false">
                <ng-container upload-cover-photo>
                    <staffnow-facility-picture-upload
                        class="input-container"
                        [buttonText]="'GENERAL.PHOTO' | translate"
                        [apiUrl]="facilityPictureUploadUrl"
                        [allowedFileTypes]="allowedFileTypesPicture"
                        [fileSizeLimit]="fileSizeLimit"
                        [imageUrl]="facilityCoverUrl"
                        [pictureType]="facilityCoverType"
                        [uuid]="facilityProfile.uuid"
                        [type]="pictureTypes.cover"
                        [isDisabled]="!canEditLogo()"
                        (successfulFileUpload)="handlePictureChange($event)"
                    >
                    </staffnow-facility-picture-upload>
                </ng-container>
                <ng-container upload-logo>
                    <staffnow-facility-picture-upload
                        class="input-container"
                        [buttonText]="'GENERAL.LOGO' | translate"
                        [apiUrl]="facilityPictureUploadUrl"
                        [allowedFileTypes]="allowedFileTypesPicture"
                        [fileSizeLimit]="fileSizeLimit"
                        [imageUrl]="facilityLogoUrl"
                        [pictureType]="facilityLogoType"
                        [uuid]="facilityProfile.uuid"
                        [type]="pictureTypes.logo"
                        [isDisabled]="!canEditLogo()"
                        (successfulFileUpload)="handleLogoChange($event)"
                    >
                    </staffnow-facility-picture-upload>
                </ng-container>
                <ng-container status>
                    <staffnow-facility-status
                        *ngIf="isMro && facilityProfile?.status"
                        [status]="facilityProfile.status"
                    ></staffnow-facility-status>
                    <div *ngIf="isBackOffice && isMro" class="status-action">
                        <general-button
                            *ngIf="hasVerifyLink()"
                            (onClick)="verifyFacility()"
                            size="small"
                            [text]="'FACILITY_PROFILE.VERIFY_FACILITY' | translate | uppercase"
                        ></general-button>
                        <general-button
                            *ngIf="hasPendingLink()"
                            (onClick)="markAsPending()"
                            backgroundStyle="outline"
                            size="small"
                            [text]="'FACILITY_PROFILE.MARK_AS_PENDING' | translate | uppercase"
                        ></general-button>
                    </div>
                </ng-container>
                <ng-container name-and-slogan>
                    <div class="profile-edit">
                        <div
                            class="title mb-3"
                            [ngClass]="{
                                error: isEmptyText(facilityProfile.name)
                            }"
                        >
                            <label for="title-input">
                                {{
                                    (isMro ? 'FACILITY_PROFILE.COMPANY_NAME' : 'FACILITY_PROFILE.AGENCY_NAME')
                                        | translate
                                }}
                                <span class="text-muted error-hint"> (required field) </span>
                            </label>
                            <input
                                type="text"
                                id="title-input"
                                autocomplete="off"
                                class="form-control"
                                [disabled]="isLoading || !canEditProfile()"
                                [(ngModel)]="facilityProfile.name"
                                (ngModelChange)="checkProfileFormIsComplete()"
                            />
                        </div>
                        <div class="title mb-3" *ngIf="isAgency">
                            <label for="slogan-input">
                                {{ 'GENERAL.SLOGAN' | translate }}
                                <span class="text-muted"> (optional)</span>
                            </label>
                            <input
                                type="text"
                                id="slogan-input"
                                autocomplete="off"
                                class="form-control"
                                maxlength="50"
                                [placeholder]="'FACILITY_PROFILE.ADVERTISE_YOUR_AGENCY_SHORT' | translate"
                                [disabled]="isLoading"
                                [(ngModel)]="facilityProfile.slogan"
                                (ngModelChange)="checkProfileFormIsComplete()"
                            />
                        </div>
                    </div>
                </ng-container>
            </staffnow-facility-profile-preview>
        </div>

        <div class="edit-wrapper">
            <tab-navigation
                *ngIf="activeTab && menuItems.length > 1"
                [menuItems]="menuItems"
                [(ngModel)]="activeTab"
            ></tab-navigation>
            <ng-container [ngSwitch]="activeTab.value">
                <ng-container
                    *ngSwitchCase="'promotional'"
                    [ngTemplateOutlet]="AGENCY_PROMOTIONAL_MATERIAL_TEMPLATE"
                ></ng-container>
                <ng-container *ngSwitchCase="'approvals'" [ngTemplateOutlet]="AGENCY_APPROVALS_TEMPLATE"></ng-container>
                <ng-container *ngSwitchCase="'backoffice'" [ngTemplateOutlet]="BACKOFFICE_TEMPLATE"></ng-container>
                <ng-container *ngSwitchDefault [ngTemplateOutlet]="PROFILE_SETTINGS_TAB"></ng-container>
            </ng-container>
        </div>
    </div>

    <!--
      --
      --
      -- TEMPLATE FOR PROFILE SETTINGS TAB
      --
      --
      -->
    <ng-template #PROFILE_SETTINGS_TAB>
        <ng-container *ngTemplateOutlet="DEMO_FACILITY_BANNER"></ng-container>
        <div class="profile-edit">
            <div class="long-text-wrapper">
                <div
                    class="presentation mb-3"
                    [ngClass]="{
                        error: isEmptyText(facilityProfile.shortPresentation)
                    }"
                >
                    <label for="short-presentation">
                        {{ 'GENERAL.SHORT_PRESENTATION' | translate }}
                        <span class="text-muted error-hint"> (required field) </span>
                    </label>
                    <textarea
                        class="form-control"
                        id="short-presentation"
                        cols="50"
                        rows="10"
                        autocomplete="off"
                        [disabled]="isLoading || !canEditProfile()"
                        [maxlength]="textAreaMaxLength"
                        (input)="charactersCounter('shortPresentation')"
                        [(ngModel)]="facilityProfile.shortPresentation"
                        (ngModelChange)="checkProfileFormIsComplete()"
                    ></textarea>
                    <staffnow-text-counter
                        [textLength]="shortPresentationCounter"
                        [maxLength]="textAreaMaxLength"
                    ></staffnow-text-counter>
                </div>
                <div class="requirements-checklist mb-3" *ngIf="isMro">
                    <label for="requirements-checklist"> Requirements checklist </label>
                    <textarea
                        class="form-control"
                        id="requirements-checklist"
                        cols="50"
                        rows="10"
                        autocomplete="off"
                        [disabled]="isLoading || !canEditProfile()"
                        [maxlength]="checklistMaxLength"
                        (input)="charactersCounter('checklist')"
                        [(ngModel)]="facilityProfile.checklist"
                        (ngModelChange)="checkProfileFormIsComplete()"
                    ></textarea>
                    <staffnow-text-counter
                        [textLength]="checklistCounter"
                        [maxLength]="checklistMaxLength"
                    ></staffnow-text-counter>
                </div>
            </div>
            <div class="long-text-wrapper">
                <div class="requirements-documents-needed-template mb-3" *ngIf="isMro">
                    <label for="requirements-documents-needed-template">
                        {{ 'GENERAL.DOCUMENTS_NEEDED_TEMPLATE' | translate }}
                    </label>
                    <textarea
                        class="form-control"
                        id="requirements-documents-needed-template"
                        cols="50"
                        rows="10"
                        autocomplete="off"
                        [disabled]="isLoading || !canEditProfile()"
                        [maxlength]="textAreaMaxLength"
                        (input)="charactersCounter('documentsNeededTemplate')"
                        [(ngModel)]="facilityProfile.documentsNeededTemplate"
                        (ngModelChange)="checkProfileFormIsComplete()"
                    ></textarea>
                    <staffnow-text-counter
                        [textLength]="documentsNeededTemplateCounter"
                        [maxLength]="textAreaMaxLength"
                    ></staffnow-text-counter>
                </div>
            </div>

            <div class="additional">
                <staffnow-mro-approvals *ngIf="hasMroApprovalsLink()"> </staffnow-mro-approvals>
            </div>

            <div *ngIf="isAgency">
                <staffnow-markups
                    [facilityProfile]="facilityProfileAsAgency"
                    (markupsChanged)="checkProfileFormIsComplete()"
                ></staffnow-markups>
            </div>

            <div class="action-buttons mt-4">
                <general-button
                    [text]="'GENERAL.CONFIRM_CHANGES' | translate"
                    [isLoading]="isLoading"
                    [isDisabled]="!canEditProfile() || !isFormCompleted"
                    [customClass]="'btn-loader'"
                    (onClick)="saveChanges()"
                ></general-button>
            </div>
        </div>
        <div class="mro-rate-settings" *ngIf="isMro">
            <staffnow-rates-table [facilityProfile]="facilityProfileAsMro"></staffnow-rates-table>
        </div>
        <div class="approved-agencies-settings" *ngIf="isMro">
            <staffnow-approved-agencies-select
                *ngIf="hasApprovedAgenciesLink()"
                [isDisabled]="!canEditProfile()"
            ></staffnow-approved-agencies-select>
        </div>
    </ng-template>

    <!--
       --
       --
       -- TEMPLATE FOR DEMO FACILITY BANNER
       --
       --
       -->
    <ng-template #DEMO_FACILITY_BANNER>
        <staffnow-information-banner
            *ngIf="!canEditProfile() && isProfileLoaded()"
            [message]="'FACILITY_PROFILE.DEMO' | translate"
        ></staffnow-information-banner>
    </ng-template>

    <!--
      --
      --
      -- CUSTOM TEMPLATE FOR BACKOFFICE TAB
      --
      --
      -->
    <ng-template #BACKOFFICE_TEMPLATE>
        <div class="backoffice-template">
            <div *ngIf="isAgency">
                <ng-container *ngTemplateOutlet="BACKOFFICE_AGENCY_TEMPLATE"></ng-container>
            </div>
            <div *ngIf="isMro">
                <ng-container *ngTemplateOutlet="BACKOFFICE_MRO_TEMPLATE"></ng-container>
            </div>
            <div class="action-buttons mt-4">
                <general-button
                    [text]="'GENERAL.CONFIRM_CHANGES' | translate"
                    [isLoading]="isLoading"
                    [isDisabled]="isLoading || !isBackofficeFormCompleted"
                    [customClass]="'btn-loader'"
                    (onClick)="saveAdminPropertiesChanges()"
                ></general-button>
            </div>
        </div>
    </ng-template>

    <!--
      --
      --
      -- CUSTOM TEMPLATE FOR BACKOFFICE AGENCY FACILITY
      --
      --
      -->
    <ng-template #BACKOFFICE_AGENCY_TEMPLATE>
        <div class="select-option group">
            <div class="mt-4">
                <h1 class="h1">
                    {{ 'FACILITY_PROFILE.AUTO_REJECT_USERS_WITHOUT_DOCS' | translate }}
                </h1>
                <p>
                    {{ 'FACILITY_PROFILE.AUTO_REJECT_USERS_WITHOUT_DOCS_DESCR' | translate }}
                    <input
                        type="number"
                        class="form-control"
                        [(ngModel)]="facilityProfile.daysBeforeAutoReject"
                        min="0"
                        (ngModelChange)="checkBackofficeFormIsComplete()"
                    />
                </p>
            </div>
        </div>
    </ng-template>

    <!--
      --
      --
      -- CUSTOM TEMPLATE FOR BACKOFFICE MRO FACILITY
      --
      --
      -->
    <ng-template #BACKOFFICE_MRO_TEMPLATE>
        <div class="select-option group">
            <div class="select-label">
                <label for="group">{{ 'FACILITY_PROFILE.AVIATION_COMPANY_GROUP' | translate }}</label>
            </div>
            <ng-select
                class="mb-3"
                id="group"
                [items]="mroGroupsList"
                [(ngModel)]="selectedGroup"
                (ngModelChange)="onGroupChangeHandler($event)"
                [searchable]="true"
                bindLabel="name"
                [placeholder]="'FACILITY_PROFILE.AVIATION_COMPANY_GROUP_LIST_PLACEHOLDER' | translate"
            >
            </ng-select>
            <div class="action-buttons mt-2">
                <div class="custom-control custom-checkbox">
                    <input
                        id="requiresAgencySelectionForOfferApplication"
                        type="checkbox"
                        class="custom-control-input"
                        [(ngModel)]="facilityProfile.requiresAgencySelectionForOfferApplication"
                        (ngModelChange)="checkBackofficeFormIsComplete()"
                    />
                    <label
                        for="requiresAgencySelectionForOfferApplication"
                        class="custom-control-label font-weight-bold"
                    >
                        {{ 'FACILITY_PROFILE.REQUIRED_AGENCY_SELECTION' | translate }}
                        <span class="text-muted">
                            {{ 'FACILITY_PROFILE.REQUIRED_AGENCY_SELECTION_DESCR' | translate }}
                        </span>
                    </label>
                </div>
            </div>
            <div class="action-buttons mt-2">
                <div class="custom-control custom-checkbox">
                    <input
                        id="allowsPackageOffers"
                        type="checkbox"
                        class="custom-control-input"
                        [(ngModel)]="facilityProfile.allowsPackageOffers"
                        (ngModelChange)="checkBackofficeFormIsComplete()"
                    />
                    <label for="allowsPackageOffers" class="custom-control-label font-weight-bold">
                        {{ 'FACILITY_PROFILE.FIXED_PRICE_JO' | translate }}
                        <span class="text-muted">{{ 'FACILITY_PROFILE.FIXED_PRICE_JO_DESCR' | translate }}</span>
                    </label>
                </div>
            </div>
            <div class="action-buttons mt-2">
                <div class="custom-control custom-checkbox">
                    <input
                        id="allowsPermanentJobOffers"
                        type="checkbox"
                        class="custom-control-input"
                        [(ngModel)]="facilityProfile.allowsPermanentJobOffers"
                        (ngModelChange)="checkBackofficeFormIsComplete()"
                    />
                    <label for="allowsPermanentJobOffers" class="custom-control-label font-weight-bold">
                        {{ 'FACILITY_PROFILE.PERMANTENT_JO' | translate }}
                        <span class="text-muted">{{ 'FACILITY_PROFILE.PERMANTENT_JO_DESCR' | translate }}</span>
                    </label>
                </div>
            </div>
            <div class="action-buttons mt-2">
                <div class="custom-control custom-checkbox">
                    <input
                        id="allowPrivateOffers"
                        type="checkbox"
                        class="custom-control-input"
                        [(ngModel)]="facilityProfile.allowPrivateOffers"
                        (ngModelChange)="checkBackofficeFormIsComplete()"
                    />
                    <label for="allowPrivateOffers" class="custom-control-label font-weight-bold">
                        {{ 'FACILITY_PROFILE.PRIVATE_JO' | translate }}
                        <span class="text-muted">
                            {{ 'FACILITY_PROFILE.PRIVATE_JO_DESCR' | translate }}
                        </span>
                    </label>
                </div>
            </div>
            <div class="action-buttons mt-2">
                <div class="custom-control custom-checkbox">
                    <input
                        id="allowsHideRateInJobOffers"
                        type="checkbox"
                        class="custom-control-input"
                        [(ngModel)]="facilityProfile.allowsHideRateInJobOffers"
                        (ngModelChange)="checkBackofficeFormIsComplete()"
                    />
                    <label for="allowsHideRateInJobOffers" class="custom-control-label font-weight-bold">
                        {{ 'FACILITY_PROFILE.HIDING_SALARY' | translate }}
                        <span class="text-muted">
                            {{ 'FACILITY_PROFILE.HIDING_SALARY_DESCR' | translate }}
                        </span>
                    </label>
                </div>
            </div>
            <div class="action-buttons mt-2">
                <div class="custom-control custom-checkbox">
                    <input
                        id="allowTcnApplicationsToggle"
                        type="checkbox"
                        class="custom-control-input"
                        [(ngModel)]="facilityProfile.allowTcnApplicationsToggle"
                        (ngModelChange)="checkBackofficeFormIsComplete()"
                    />
                    <label for="allowTcnApplicationsToggle" class="custom-control-label font-weight-bold">
                        {{ 'FACILITY_PROFILE.ALLOW_TCN' | translate }}
                        <span class="text-muted">
                            {{ 'FACILITY_PROFILE.ALLOW_TCN_MUTED' | translate }}
                        </span>
                    </label>
                </div>
            </div>
            <div class="action-buttons mt-2">
                <div class="custom-control custom-checkbox">
                    <input
                        id="allowExperienceLetterRequest"
                        type="checkbox"
                        class="custom-control-input"
                        [(ngModel)]="facilityProfile.allowExperienceLetterRequest"
                        (ngModelChange)="checkBackofficeFormIsComplete()"
                    />
                    <label for="allowExperienceLetterRequest" class="custom-control-label font-weight-bold">
                        {{ 'FACILITY_PROFILE.ALLOW_EXPERIENCE_LETTERS' | translate }}
                        <span class="text-muted">
                            {{ 'FACILITY_PROFILE.ALLOW_EXPERIENCE_LETTERS_DESCRIPTION' | translate }}
                        </span>
                    </label>
                </div>
            </div>
            <div class="action-buttons mt-2">
                <div class="custom-control custom-checkbox">
                    <input
                        id="offerDoubleCheck"
                        type="checkbox"
                        class="custom-control-input"
                        [(ngModel)]="facilityProfile.offerDoubleCheck"
                        (ngModelChange)="checkBackofficeFormIsComplete()"
                    />
                    <label for="offerDoubleCheck" class="custom-control-label font-weight-bold">
                        {{ 'FACILITY_PROFILE.DOUBLE_CHECK' | translate }}
                        <span class="text-muted">
                            {{ 'FACILITY_PROFILE.DOUBLE_CHECK_DESCR' | translate }}
                        </span>
                    </label>
                </div>
            </div>
            <div class="action-buttons mt-2">
                <div class="custom-control custom-checkbox">
                    <input
                        id="offersHandledCentrally"
                        type="checkbox"
                        class="custom-control-input"
                        [(ngModel)]="facilityProfile.offersHandledCentrally"
                        (ngModelChange)="checkBackofficeFormIsComplete()"
                    />
                    <label for="offersHandledCentrally" class="custom-control-label font-weight-bold">
                        {{ 'FACILITY_PROFILE.JO_HANDLED_CENTRALLY' | translate }}
                        <span class="text-muted">
                            {{ 'FACILITY_PROFILE.JO_HANDLED_CENTRALLY_DESCR' | translate }}
                        </span>
                    </label>
                </div>
            </div>
            <div class="action-buttons mt-2">
                <div class="custom-control custom-checkbox">
                    <input
                        id="hideMroInformationInJobOffers"
                        type="checkbox"
                        class="custom-control-input"
                        [(ngModel)]="facilityProfile.hideMroInformationInJobOffers"
                        (ngModelChange)="checkBackofficeFormIsComplete()"
                    />
                    <label for="hideMroInformationInJobOffers" class="custom-control-label font-weight-bold">
                        {{ 'FACILITY_PROFILE.HIDE_AVIATION_COMPANY_JO' | translate }}
                        <span class="text-muted">
                            {{ 'FACILITY_PROFILE.HIDE_AVIATION_COMPANY_JO_DESCR' | translate }}
                        </span>
                    </label>
                </div>
            </div>
            <div class="mt-4">
                <h1 class="h1">{{ 'FACILITY_PROFILE.WORKFLOW' | translate }}</h1>
                <p>
                    {{ 'FACILITY_PROFILE.WORKFLOW_DESCR' | translate }}
                </p>
                <div class="mro-workflow">
                    <div class="custom-control custom-checkbox">
                        <input
                            id="requiresTechnicianAcceptance"
                            type="checkbox"
                            class="custom-control-input"
                            [(ngModel)]="facilityProfile.requiresTechnicianAcceptance"
                            disabled
                        />
                        <label for="requiresTechnicianAcceptance" class="custom-control-label font-weight-bold">
                            {{ 'GENERAL.TECHNICIAN' | translate }}
                        </label>
                    </div>
                    <div class="custom-control custom-checkbox">
                        <input
                            id="requiresProductionAcceptance"
                            type="checkbox"
                            class="custom-control-input"
                            [(ngModel)]="facilityProfile.requiresProductionAcceptance"
                            (ngModelChange)="checkBackofficeFormIsComplete()"
                        />
                        <label for="requiresProductionAcceptance" class="custom-control-label font-weight-bold">
                            {{ 'FACILITY_PROFILE.AVIATION_COMPANY_PRODUCTION' | translate }}
                        </label>
                    </div>
                    <div class="custom-control custom-checkbox">
                        <input
                            id="requiresQualityAcceptance"
                            type="checkbox"
                            class="custom-control-input"
                            [(ngModel)]="facilityProfile.requiresQualityAcceptance"
                            (ngModelChange)="checkBackofficeFormIsComplete()"
                        />
                        <label for="requiresQualityAcceptance" class="custom-control-label font-weight-bold">
                            {{ 'FACILITY_PROFILE.AVIATION_COMPANY_QUALITY' | translate }}
                        </label>
                    </div>
                    <div class="custom-control custom-checkbox">
                        <input
                            id="requiresHumanResourcesAcceptance"
                            type="checkbox"
                            class="custom-control-input"
                            [(ngModel)]="facilityProfile.requiresHumanResourcesAcceptance"
                            (ngModelChange)="checkBackofficeFormIsComplete()"
                        />
                        <label for="requiresHumanResourcesAcceptance" class="custom-control-label font-weight-bold">
                            {{ 'FACILITY_PROFILE.AVIATION_COMPANY_HUMAN_RESOURCES' | translate }}
                        </label>
                    </div>
                    <div class="custom-control custom-checkbox">
                        <input
                            id="requiresAgencyAcceptance"
                            type="checkbox"
                            class="custom-control-input"
                            [(ngModel)]="facilityProfile.requiresAgencyAcceptance"
                            (ngModelChange)="checkBackofficeFormIsComplete()"
                        />
                        <label for="requiresAgencyAcceptance" class="custom-control-label font-weight-bold">
                            {{ 'GENERAL.AGENCY' | translate }}
                        </label>
                    </div>
                </div>
                <p class="text-muted">
                    {{ 'FACILITY_PROFILE.AVIATION_COMPANY_TYPE' | translate }}
                </p>
            </div>
            <div class="mt-4">
                <h1 class="h1">
                    {{ 'FACILITY_PROFILE.AUTO_REJECT_USERS_WITHOUT_DOCS' | translate }}
                </h1>
                <p>
                    {{ 'FACILITY_PROFILE.AUTO_REJECT_USERS_WITHOUT_DOCS_DESCR' | translate }}
                    <input
                        type="number"
                        class="form-control"
                        [(ngModel)]="facilityProfile.daysBeforeAutoReject"
                        min="0"
                        (ngModelChange)="checkBackofficeFormIsComplete()"
                    />
                </p>
            </div>
        </div>
    </ng-template>

    <!--
          --
          --
          -- CUSTOM TEMPLATE FOR AGENCY PROMOTIONAL MATERIAL
          --
          --
          -->
    <ng-template #AGENCY_PROMOTIONAL_MATERIAL_TEMPLATE>
        <div class="mb-5 mt-5">
            <staffnow-text-editor
                maxLength="700"
                value="{{ facilityProfile.services }}"
                (onChange)="updateServices($event)"
            ></staffnow-text-editor>
            <general-button
                [text]="'GENERAL.CONFIRM_CHANGES' | translate"
                [isLoading]="isLoading"
                [isDisabled]="!canEditProfile() || !isServicesDescriptionUpdated"
                [customClass]="'btn-loader'"
                (onClick)="saveServices()"
            ></general-button>
        </div>
        <div class="mb-5">
            <label class="h1" for="default-contract-template">
                {{ 'FACILITY_PROFILE.DEFAULT_CONTRAT_TEMPLATE' | translate }}
            </label>
            <span class="text-muted" id="default-contract-template">{{
                'FACILITY_PROFILE.VISIBLE_TO_AC_ONLY' | translate
            }}</span>
            <br />
            <div>
                <staffnow-file-upload-2
                    [documents]="defaultContract"
                    (fileDeleted)="removeFile($event)"
                    (uploadSucceeded)="onSuccessfulFileUpload()"
                    (downloaded)="downloadFile($event)"
                    [uploadUrl]="defaultContractUrl"
                    documentName="Default Contract"
                    [allowedFileTypes]="allowedFileTypesDocument"
                    [noDocumentsUploadedDisclaimer]="'FILES.AGENCY_NO_DOC_UPLOADED_DISCLAIMER'"
                >
                </staffnow-file-upload-2>
            </div>
        </div>
        <staffnow-image-uploader [facilityProfile]="facilityProfile" [pictures]="pictures"></staffnow-image-uploader>
    </ng-template>

    <!--
        --
        --
        -- CUSTOM TEMPLATE FOR AGENCY APPROVALS
        --
        --
        -->
    <ng-template #AGENCY_APPROVALS_TEMPLATE>
        <div class="agency-approvals">
            <div class="mb-5">
                <label class="h1" for="agency-approvals">
                    {{ 'PACKAGE_CONTRACT_CARD.AGENCY_APPROVALS' | translate }}
                    <span class="text-muted" id="agency-approvals">{{
                        'FACILITY_PROFILE.VISIBLE_TO_AC_ONLY' | translate
                    }}</span>
                </label>
                <br />
                <div>
                    <staffnow-file-upload-2
                        [documents]="agencyApprovals"
                        (fileDeleted)="removeAgencyApprovals($event)"
                        (uploadSucceeded)="onSuccessfulFileUpload()"
                        (downloaded)="downloadAgencyApprovals($event)"
                        [uploadUrl]="agencyApprovalsUrl"
                        documentName="Agency Approvals"
                        [allowedFileTypes]="allowedFileTypesDocument"
                        [maximumFilesLimit]="10"
                        fileListHeight="600px"
                        [showLabel]="true"
                        (labelSet)="handleSetLabel($event)"
                        [tooltip]="'FACILITY_PROFILE.FILE_UPLOAD_TOOLTIP' | translate"
                        [noDocumentsUploadedDisclaimer]="'FILES.AGENCY_NO_DOC_UPLOADED_DISCLAIMER'"
                    >
                    </staffnow-file-upload-2>
                </div>
            </div>
        </div>
    </ng-template>
</ng-template>
